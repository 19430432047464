import { IonSplitPane, IonRouterOutlet } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"
import { Route, Redirect } from "react-router"
import Menu from "./components/Menu"
import Page from "./pages/Page"
import { User } from "firebase/auth"
import { useEffect, useState } from "react"
import { QueryClient, QueryClientProvider } from 'react-query'
import { auth, db } from "./config/firebase"
import { doc, getDoc } from "firebase/firestore"
import LoginPage from "./pages/Login"
import HomePage from "./pages/Home"
import OnboardingPage from "./pages/Onboarding"

const Dashboard: React.FC = () => {
    const [user, setUser] = useState<User | null>(null);
    const [admin, setAdmin] = useState<any>(null);

    const queryClient = new QueryClient()

    useEffect(() => {
        auth().onAuthStateChanged(async (user) => {
            if (user) {
                const result = await getDoc(doc(db, 'VILLAGERS', user.uid));
                if (result.exists()) {
                    console.warn('Fetched admin/villager; ', result.data());
                    setAdmin(result.data());
                    setUser(user);
                }
            } else {
                setUser(null);
                setAdmin(null)
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <IonReactRouter>
            {admin ? (
                <QueryClientProvider client={queryClient}>
                    <IonSplitPane contentId="main">
                        <Menu admin={admin}/>
                        <IonRouterOutlet id="main">
                            <Route exact path="/home">
                                <HomePage admin={admin} />
                            </Route>
                            <Route exact path="/onboarding">
                                <OnboardingPage admin={admin}/>
                            </Route>
                        </IonRouterOutlet>
                    </IonSplitPane>
                </QueryClientProvider>
            ) : (
                <IonRouterOutlet>
                    <Route path="/login">
                        <LoginPage setUser={setUser} setAdmin={setAdmin} />
                    </Route>
                    <Redirect to="/login" />
                </IonRouterOutlet>
            )}

        </IonReactRouter>
    )

}

export default Dashboard;
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from '@ionic/react';

import { useHistory, useLocation } from 'react-router-dom';
import { book, bookSharp, homeOutline, homeSharp, logOut, people, trailSign, trailSignSharp, } from 'ionicons/icons';
import './Menu.css';
import { Villager } from '../models/villager.model';
import { getAuth, signOut } from 'firebase/auth';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

const appPages: AppPage[] = [
  {
    title: 'Home',
    url: '/home',
    iosIcon: homeOutline,
    mdIcon: homeSharp
  },
  {
    title: 'Onboarding',
    url: '/onboarding',
    iosIcon: trailSign,
    mdIcon: trailSignSharp,
  }
  // {
  //   title: 'Outbox',
  //   url: '/page/Outbox',
  //   iosIcon: paperPlaneOutline,
  //   mdIcon: paperPlaneSharp
  // },
];

interface MenuProps {
  admin: Villager | null;
}


const Menu: React.FC<MenuProps> = ({ admin }) => {
  const location = useLocation();
  const history = useHistory();

  const logout = () => {
    const auth = getAuth();
    signOut(auth);
    history.push('/');
  }

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>RVR Dash</IonListHeader>
          <IonNote>{admin?._EMAIL}</IonNote>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon aria-hidden="true" slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonItem style={{ marginTop: '100px' }} onClick={logout}>
            <IonLabel>Logout</IonLabel>
            <IonIcon slot="end" icon={logOut} />
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;

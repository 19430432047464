import { DocumentData, collection, query } from "firebase/firestore";
import { Villager } from "../models/villager.model";
import { db } from "../config/firebase";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonLabel,
  IonCol,
  IonGrid,
  IonRow,
  IonLoading,
  IonToast,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonButton,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import './Onboarding.css'

interface OnboardingProps {
  admin: Villager | null;
}

const OnboardingPage: React.FC<OnboardingProps> = ({ admin }) => {
  const ref = query(collection(db, "VILLAGERS"));
  const { isError, data, refetch, isLoading } = useFirestoreQuery(
    ["villagers"],
    ref,
    {
      subscribe: false,
    }
  );
  const [villagers, setVillagers] = useState<Villager[]>([]);

  const [noDmEmails, setNoDmEmails] = useState("");
  const [noChannelMessageEmails, setNoChannelMessageEmails] = useState("");
  const [noPostMessageEmails, setNoPostMessageEmails] = useState("");
  const [noPostEmails, setNoPostEmails] = useState("");
  const [noProfilePicEmails, setNoProfilePhotoEmails] = useState("");
  const [noEventEmails, setNoCreatedEventEmails] = useState("");
  const [noFeedbackEmails, setNoFeedbackEmails] = useState("");
  const [noBioEmails, setNoBioEmails] = useState("");
  const [noViewBoardEmails, setNoViewBoardEmails] = useState("");
  const [noViewVillagePageEmails, setNoViewVillagePageEmails] = useState("");
  const [noCreateCircleEmails, setNoCreateCircleEmails] = useState("");
  const [noCreateChannelEmails, setNoCreateChannelEmails] = useState("");
  const [noSentInviteEmails, setNoSentInviteEmails] = useState("");
  const [noAddVillagerToCircleEmails, setNoAddVillagerToCircleEmails] =
    useState("");
  const [noAddLeaderToCircleEmails, setNoAddLeaderToCircleEmails] =
    useState("");

  useEffect(() => {
    if (data) {
      const villagers = data.docs.map((doc: DocumentData) => ({
        _UID: doc.id,
        ...(doc.data() as Villager),
      }));
      setVillagers(villagers);
    }
  }, [data]);

  useEffect(() => {
    function getEmails(villagerField: string): string {
      let emails = "";
      villagers
        .filter(
          (v) =>
            v[villagerField as keyof Villager] === undefined ||
            !v[villagerField as keyof Villager]
        )
        .forEach((v) => {
          emails += v._EMAIL + ", ";
        });
      console.log(villagerField, ": ", emails);
      return emails;
    }
    setNoDmEmails(getEmails("HAS_SENT_DM"));
    setNoChannelMessageEmails(getEmails("HAS_SENT_CHANNEL_MESSAGE"));
    setNoPostMessageEmails(getEmails("HAS_SENT_POST_MESSAGE"));
    setNoProfilePhotoEmails(getEmails("HAS_PROFILE_PIC"));
    setNoPostEmails(getEmails("HAS_CREATED_POST"));
    setNoCreatedEventEmails(getEmails("HAS_CREATED_EVENT"));
    setNoFeedbackEmails(getEmails("HAS_SENT_FEEDBACK"));
    setNoBioEmails(getEmails("HAS_COMPLETED_BIO"));
    setNoViewBoardEmails(getEmails("HAS_VIEWED_BULLETIN_BOARD"));
    setNoViewVillagePageEmails(getEmails("HAS_VIEW_VILLAGE_PAGE"));
    setNoCreateCircleEmails(getEmails("HAS_CREATED_CIRCLE"));
    setNoCreateChannelEmails(getEmails("HAS_CREATED_CHANNEL"));
    setNoSentInviteEmails(getEmails("HAS_SENT_INVITE"));
    setNoAddVillagerToCircleEmails(getEmails("HAS_ADDED_VILLAGER_TO_CIRCLE"));
    setNoAddLeaderToCircleEmails(getEmails("HAS_ADDED_VILLAGER_TO_CIRCLE"));
  }, [villagers]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Onboarding</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        <IonToast
          isOpen={isError}
          message="Error talking to the internet. ChatGPT has been notified"
        />
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Sent DM
                    <CopyToClipboard text={noDmEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) => v.HAS_SENT_DM !== undefined && !v.HAS_SENT_DM
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Sent Channel Message
                    <CopyToClipboard text={noChannelMessageEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_SENT_CHANNEL_MESSAGE !== undefined &&
                        !v.HAS_SENT_CHANNEL_MESSAGE
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Sent Post Message
                    <CopyToClipboard text={noPostMessageEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_SENT_POST_MESSAGE !== undefined &&
                        !v.HAS_SENT_POST_MESSAGE
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Created Post
                    <CopyToClipboard text={noPostEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_CREATED_POST !== undefined && !v.HAS_CREATED_POST
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Added Profile Picture
                    <CopyToClipboard text={noProfilePicEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_PROFILE_PIC !== undefined && !v.HAS_PROFILE_PIC
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Created Event
                    <CopyToClipboard text={noEventEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_CREATED_EVENT !== undefined &&
                        !v.HAS_CREATED_EVENT
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Sent Feedback
                    <CopyToClipboard text={noFeedbackEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_SENT_FEEDBACK !== undefined &&
                        !v.HAS_SENT_FEEDBACK
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Completed Bio
                    <CopyToClipboard text={noBioEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_COMPLETED_BIO !== undefined &&
                        !v.HAS_COMPLETED_BIO
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Viewed Bulletin Board
                    <CopyToClipboard text={noViewBoardEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_VIEWED_BULLETIN_BOARD !== undefined &&
                        !v.HAS_VIEWED_BULLETIN_BOARD
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Viewed Village Page
                    <CopyToClipboard text={noViewVillagePageEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_VIEW_VILLAGE_PAGE !== undefined &&
                        !v.HAS_VIEW_VILLAGE_PAGE
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Created Circles
                    <CopyToClipboard text={noCreateCircleEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_CREATED_CIRCLE !== undefined &&
                        !v.HAS_CREATED_CIRCLE
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Created Channels
                    <CopyToClipboard text={noCreateChannelEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_CREATED_CHANNEL !== undefined &&
                        !v.HAS_CREATED_CHANNEL
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>

          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Sent Invites
                    <CopyToClipboard text={noSentInviteEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_SENT_INVITE !== undefined && !v.HAS_SENT_INVITE
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Added Villagers To Circles
                    <CopyToClipboard text={noAddVillagerToCircleEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_ADDED_VILLAGER_TO_CIRCLE !== undefined &&
                        !v.HAS_ADDED_VILLAGER_TO_CIRCLE
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Has Not Added Leader To Circles
                    <CopyToClipboard text={noAddLeaderToCircleEmails}>
                      <div>
                        <IonButton>Copy to Clipboard</IonButton>
                      </div>
                    </CopyToClipboard>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent className="onboarding-card-content">
                  {villagers
                    .filter(
                      (v) =>
                        v.HAS_ADDED_LEADER_TO_CIRCLE !== undefined &&
                        !v.HAS_ADDED_LEADER_TO_CIRCLE
                    )
                    .map((x: Villager) => (
                      <IonItem key={x._UID} detail>
                        <IonLabel>
                          {x.FIRST_NAME} {x.LAST_NAME}
                        </IonLabel>
                      </IonItem>
                    ))}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default OnboardingPage;

/**
 * this just needs to be imported somewhere
 * then it'll be initialized globally and accesible throughout the app
 */
import { getApp, initializeApp } from 'firebase/app';
import {
  Auth,
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth
} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { Capacitor } from '@capacitor/core';

console.warn('Env: ', process.env.REACT_APP_ENV);

const config =
  process.env.REACT_APP_ENV === 'production'
    ? {
        apiKey: 'AIzaSyByKB_yX_rICJ0IlIPoJcrDK_v8_MMLdT4',
        authDomain: 'unity-ddev.firebaseapp.com',
        projectId: 'unity-ddev',
        storageBucket: 'unity-ddev.appspot.com',
        messagingSenderId: '643054741456',
        appId: '1:643054741456:web:89a18eafd9ef6fc031bbd5',
        measurementId: 'G-V2344H5V05'
      }
    : {
        apiKey: 'AIzaSyA4TewD_M_RtEevJuCvEWPgoHBF8FJjya0',
        authDomain: 'revillager-unity-dev.firebaseapp.com',
        projectId: 'revillager-unity-dev',
        storageBucket: 'revillager-unity-dev.appspot.com',
        messagingSenderId: '667930757999',
        appId: '1:667930757999:web:75c4650803c92567ed769a',
        measurementId: 'G-W97YEH7KLN'
      };

console.warn('Firebase config: ', config);

const app = initializeApp(config);

export const functions = getFunctions(app);
export const auth = (): Auth => {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(getApp(), {
      persistence: indexedDBLocalPersistence
    });
  } else {
    // TODO figure out if we need to setPersistence(browserLocalPersistence);
    return getAuth(app);
  }
};
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

// connectFunctionsEmulator(functions, "localhost", 5001);

import { User, UserCredential, signInWithEmailAndPassword } from "firebase/auth";
import { Villager } from "../models/villager.model";
import { useState } from "react";
import { auth, db } from "../config/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import useToastError from "../hooks/useToastError";
import { doc, getDoc } from "firebase/firestore";
import { useHistory } from "react-router";
import { IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonItem, IonLabel, IonInput, IonButton, IonSpinner } from "@ionic/react";

import './Login.css'

interface LoginProps {
    setUser: (user: User | null) => void;
    setAdmin: (admin: Villager) => void;
}

const LoginPage: React.FC<LoginProps> = ({ setUser, setAdmin }) => {
    const displayError = useToastError();
    const history = useHistory();
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const getAdmin = async (authUser: UserCredential) => {
        setLoading(true)
        const result = await getDoc(doc(db, 'VILLAGERS', authUser.user.uid));
        if (result.exists()) {
            setUser(authUser.user)
            setAdmin(result.data() as Villager);
            history.push('/home');
        } else {
            displayError({ message: 'Error talking to the internet. ChatGPT has been notified' })
        }
        setLoading(false)
    }


    const signIn = async () => {
        setLoading(true);
        if (email && password) {

            const functions = getFunctions();
            const checkAdmin = httpsCallable(functions, 'perm-isAdmin');
            const result: any = await checkAdmin({ email });
            if (result.data.isAdmin) {
                const authInstance = auth();
                signInWithEmailAndPassword(authInstance, email, password)
                    .then((x: UserCredential) => getAdmin(x))
                    .catch((error) => {
                        console.error(error)
                        displayError(error)
                        setLoading(false)
                    });
            } else {
                displayError({ message: 'You are not authorized to access this app.' })
                setLoading(false)
            }
        } else {
            displayError({ message: 'Missing email or password' })
            setLoading(false)
        }
    }


    return (
        <>
            <IonCard className="login-card">
                <IonCardTitle>
                    <IonCardHeader>Welcome Admin</IonCardHeader>
                </IonCardTitle>
                <IonCardContent>
                    <IonItem fill="outline">
                        <IonLabel position="floating">Email</IonLabel>
                        <IonInput
                            value={email}
                            onIonInput={(e: any) => setEmail(e.detail.value!)}
                        />
                    </IonItem>

                    <IonItem fill="outline">
                        <IonLabel position="floating">Password</IonLabel>
                        <IonInput
                            value={password}
                            type="password"
                            onIonInput={(e: any) => setPassword(e.detail.value!)}
                            clearInput
                        />
                    </IonItem>

                    <IonButton style={{ marginTop: '20px' }} shape="round" expand="block" onClick={signIn}>Sign In
                        {loading && <IonSpinner slot="end" style={{ marginLeft: '20px' }} />}</IonButton>

                </IonCardContent>
            </IonCard>
        </>
    )

}

export default LoginPage;
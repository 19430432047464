import { IonApp, IonButton, IonContent, IonPage, IonRouterOutlet, IonSplitPane, isPlatform, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import Menu from './components/Menu';
import Page from './pages/Page';
import { addDoc, collection } from 'firebase/firestore';
import { ErrorBoundary } from "react-error-boundary";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { db } from './config/firebase';
import Dashboard from './Dashboard';

setupIonicReact({
  mode: isPlatform('android') ? 'md' : 'ios',
});

const Fallback = (props: any) => {
  const { error, resetErrorBoundary } = props;
  return (
    <IonPage>
      <IonContent className="ion-padding" style={{ textAlign: 'center' }}>
        <p>Something went wrong...</p>
        <p>{error.message}</p>
        <IonButton expand="block" color="dark" shape="round" onClick={resetErrorBoundary} > Try Again </IonButton>
      </IonContent>
    </IonPage>
  );
}

const logError = async (error: Error, info: any) => {

  const { message, stack } = error;

  console.log(error, info);
  const docRef = await addDoc(collection(db, 'ERRORS'), {
    message,
    stack,
    info: info ? info.componentStack : null
  })
  console.log('Error logged: ', docRef.id)
}


const App: React.FC = () => {
  return (
    <IonApp>
      <ErrorBoundary FallbackComponent={Fallback} onError={logError}>
          <Dashboard/>
      </ErrorBoundary>
    </IonApp>
  );
};

export default App;


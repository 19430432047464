import { DocumentData, collection, query } from "firebase/firestore";
import { Villager } from "../models/villager.model";
import { db } from "../config/firebase";
import { useFirestoreQuery } from "@react-query-firebase/firestore";
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonLabel, IonCol, IonGrid, IonRow, IonLoading, IonToast, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonListHeader, IonButton } from "@ionic/react";
import { useEffect, useState } from "react";

interface HomeProps {
    admin: Villager | null;
}

const HomePage: React.FC<HomeProps> = ({ admin }) => {

    const ref = query(collection(db, 'VILLAGERS'));
    const { isError, data, refetch, isLoading } = useFirestoreQuery(["villagers"], ref, {
        subscribe: false,
    });

    const [villagers, setVillagers] = useState<Villager[]>([]);
    const [selectedVillager, setSelectedVillager] = useState<Villager | null>(null);


    useEffect(() => {
        if (data) {
            const villagers = data.docs.map((doc: DocumentData) => ({
                _UID: doc.id,
                ...doc.data() as Villager
            }));
            setVillagers(villagers);
        }
    }, [data])

    return (

        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Home</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonLoading isOpen={isLoading} />
                <IonToast isOpen={isError} message="Error talking to the internet. ChatGPT has been notified" />
                <IonGrid>
                    <IonRow>
                        <IonCol size="4">
                            <IonList>
                                <IonListHeader>
                                    Villagers
                                    <IonButton style={{float: 'right'}} onClick={() => refetch()}>Refresh</IonButton>
                                </IonListHeader>
                                {villagers.map((x: Villager) => (
                                    <IonItem key={x._UID} detail onClick={() => setSelectedVillager(x)}>
                                        <IonLabel>{x.FIRST_NAME} {x.LAST_NAME}</IonLabel>
                                    </IonItem>
                                ))}
                            </IonList>
                        </IonCol>
                        <IonCol size="8">
                            {selectedVillager && (
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>{selectedVillager.FIRST_NAME} {selectedVillager.LAST_NAME}</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <IonItem>
                                            <IonLabel>UID</IonLabel>
                                            <IonLabel>{selectedVillager._UID}</IonLabel>
                                        </IonItem>
                                        <IonItem>
                                            <IonLabel>Email</IonLabel>
                                            <IonLabel>{selectedVillager._EMAIL}</IonLabel>
                                        </IonItem>
                                        </IonCardContent>

                                </IonCard>
                            )}
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>

    )

}

export default HomePage;
